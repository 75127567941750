$(document).ready(function () {
  $(".location i").addClass("brinca-brinca");
  $("body").css("visibility", "visible");
  $("body").addClass("fadeTofade");
  var domain = "https://www.lakewood.3margaritas.com/";
  var home_ = domain;
  var about_ = domain + "about";
  var menu_ = domain + "menu";
  var specials_ = domain + "specials";
  var catering_ = domain + "catering";
  var parties_ = domain + "parties";
  var jobs_ = domain + "jobs";
  var accessibility_ = domain + "accessibility";
  const pages = [
    { page: "home", file: home_ },
    { page: "about", file: about_ },
    { page: "menu", file: menu_ },
    { page: "specials", file: specials_ },
    { page: "catering", file: catering_ },
    { page: "parties", file: parties_ },
    { page: "jobs", file: jobs_ },
    { page: "accessibility", file: accessibility_ },
  ];

  $(
    ".home, .about, .menu, .specials, .catering, .parties, .jobs, .accessibility"
  ).each(function () {
    $(this).click(function () {
      var clickedClass = $(this).attr("class").split(" ")[0];
      var pageObj = pages.find(function (page) {
        return page.page === clickedClass;
      });
      if (pageObj) {
        var file = pageObj.file;
        window.location.href = file;
      }
    });
  });

  function agregarClaseNavActivo() {
    var currentPath = window.location.pathname;
    $(".page").each(function () {
      var link = $(this).text().toLowerCase();
      if (currentPath.includes(link)) {
        $(this).addClass("nav_activo");
      }
    });
    if (currentPath.endsWith("/")) {
      $(".home").addClass("nav_activo");
    }
  }
  $(document).ready(function () {
    agregarClaseNavActivo();
  });

  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 150) {
      $("header").addClass("sticky_header");
      $(".up").addClass("show_btn");
      $(".opn-cls span").addClass("achica");
    } else {
      $("header").removeClass("sticky_header");
      $(".up").removeClass("show_btn");
      $(".opn-cls span").removeClass("achica");
    }
  });

  var upUp = $(".up");
  upUp.on("click", function () {
    $(window).scrollTop(0);
  });

  $(".opn-cls-orders").on("click", function (event) {
    event.stopPropagation();
    $(".nav-box").toggleClass("expande");
    $(".opn-cls-orders i").toggleClass("rotate-it");
  });

  $(document).on("click", function (event) {
    if (
      !$(event.target).closest(".nav-box").length &&
      !$(event.target).closest(".opn-cls-orders").length
    ) {
      $(".nav-box").removeClass("expande");
      $(".opn-cls-orders i").removeClass("rotate-it");
    }
  });

  $(".opn-cls").on("click", function () {
    $(".wrap_links").toggleClass("open--cls");
    $(".opn-cls span").toggleClass("equis");
  });

  $(document).on("click", function (event) {
    if (
      !$(event.target).closest("nav").length &&
      $(".wrap_links").hasClass("open--cls")
    ) {
      $(".wrap_links").removeClass("open--cls");
      $(".opn-cls span").removeClass("equis");
    }
  });

  function menuTabs() {
    var tabs = $(".menu_tabs");
    var menuDivs = $(".mm");
    menuDivs.hide();
    menuDivs.eq(0).show();
    tabs.eq(0).addClass("colorea");
    tabs.each(function (index) {
      $(this).click(function () {
        menuDivs.hide();
        menuDivs.eq(index).show();
        tabs.removeClass("colorea");
        $(this).addClass("colorea");
      });
    });
  }
  menuTabs();

  $(".year").each(function () {
    var currentDate = new Date();
    var ANHO = currentDate.getFullYear();
    $(this).text(ANHO);
  });

  var currentDate = new Date().toISOString();
  var ogUpdatedTimeMeta = $("#ogUpdatedTime");
  if (ogUpdatedTimeMeta.length > 0) {
    ogUpdatedTimeMeta.attr("content", currentDate);
  }

  $(document).ready(function () {
    // Selecciona todas las imágenes en el documento
    $("img").each(function () {
      // Obtén la URL actual de la imagen
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src + "?v=" + new Date().getTime());
    });
  });

  DESC01 = "order your favorite dishes in seconds!";
  DESC02 = "subscribe to <br> our vip club!";
  //Agency
  RESTAURANTLY = "https://restaurantly.io/";
  //Agency
  $(".agency").on("click", function () {
    window.open(RESTAURANTLY, "_blank");
  });
  //Logo
  $("header img").css("cursor", "pointer");
  $("header img").on("click", function () {
    window.location.href = domain;
  });
});
